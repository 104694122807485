<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="226" height="41" viewBox="0 0 226 41" fill="none">
            <path d="M0.748748 25.528C0.471718 33.5736 6.76936 40.3204 14.8149 40.5974C22.8605 40.8744 29.6073 34.5768 29.8843 26.5312C30.1613 18.4857 23.8637 11.7389 15.8181 11.4618C7.77257 11.1848 1.02578 17.4824 0.748748 25.528ZM88.5906 19.203L88.3767 16.4783L88.3767 16.4783L88.5906 19.203ZM177.093 15.6976L177.127 18.4305L177.093 15.6976ZM196.734 15.0154C196.457 23.061 202.754 29.8078 210.8 30.0848C218.846 30.3618 225.592 24.0642 225.869 16.0186C226.146 7.97306 219.849 1.22627 211.803 0.949243C203.758 0.672213 197.011 6.96985 196.734 15.0154ZM15.2225 28.7611C27.6998 29.1907 40.103 27.9382 52.346 26.361C64.6569 24.775 76.7049 22.8776 88.8045 21.9277L88.3767 16.4783C76.0966 17.4424 63.755 19.3799 51.6476 20.9396C39.4723 22.5081 27.4283 23.712 15.4106 23.2982L15.2225 28.7611ZM88.8045 21.9277C118.126 19.6258 147.663 18.7978 177.127 18.4305L177.059 12.9648C147.546 13.3326 117.873 14.1627 88.3767 16.4783L88.8045 21.9277ZM177.127 18.4305C182.7 18.361 188.354 18.6129 194.193 18.7372C199.973 18.8602 205.852 18.8539 211.595 18.2343L211.008 12.7997C205.628 13.3802 200.036 13.3941 194.309 13.2723C188.642 13.1517 182.753 12.8938 177.059 12.9648L177.127 18.4305Z" fill="#7BBE64"/>
        </svg>
    </div>
</template>
<script>
export default {
    name: "StrikeLineSvg"
    
}
</script>
<style>
    
</style>