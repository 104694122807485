<template>
    <div class=" p-8">
        <h1 class="text-3xl font-bold">Settings</h1>

    </div>
</template>

<script>
import AdminStat from '@/components/AdminStat.vue'

export default {
    name: "AdminSettingsView",
    components: { AdminStat }
}
</script>

<style scoped>

</style>