<template>
    <div>

        <h2>1. Acceptance of Terms</h2>
        <ul>
            <li>1.1. By accessing and using Apex Technologies, you agree to comply with and be bound by these terms and conditions.</li>
            <li>1.2. If you do not agree with any part of these terms, you must not use our services.</li>
        </ul>



        <h2>2. User Eligibility</h2>
        <ul>
            <li>2.1. You must be at least 18 years old to use our services.</li>
            <li>2.2. By using our services, you represent and warrant that you have the right, authority, and capacity to enter into this agreement.</li>
        </ul>


        <h2>3. Registration and User Accounts</h2>
        <ul>
            <li>3.1. You are required to create an account to access most of the features of our website.</li>
            <li>3.2. You are responsible for maintaining the confidentiality of your account information, and you agree to notify us immediately of any unauthorized use of your account.</li>
        </ul>


        <h2>4. Job Listings and Applications</h2>
        <ul>
            <li>4.1. Apex Technologies provides a platform for employers to post job listings and for Freelancers to apply.</li>
            <li>4.2. We do not guarantee the accuracy, completeness, or reliability of job listings or the suitability of applicants.</li>
            <li>4.3. Employers and freelancers are responsible for conducting their own due diligence before entering into any employment agreements.</li>
        </ul>



        <h2>5. User Content</h2>
        <ul>
            <li>5.1. Users are solely responsible for the content they post on Apex Technologies.</li>
            <li>5.2. By submitting content, you grant us a non-exclusive, royalty-free, worldwide, perpetual license to use, reproduce, and distribute the content.</li>
            <li>5.3. The content on the Website is provided for general information purposes only. It may be subject to change without notice.</li>
            <li>5.4. You acknowledge and agree that Apex Technologies reserves the right to remove or modify any content on the Website for any reason, without prior notice.</li>
        </ul>



        <h2>6. Privacy Policy</h2>
        <ul>
            <li>6.1. Our privacy policy outlines how we collect, use, and protect your personal information. By using our services, you agree to our privacy policy.</li>
        </ul>



        <h2>7. Termination of Accounts</h2>
        <ul>
            <li>7.1. We reserve the right to terminate or suspend user accounts at our discretion, without notice, for any violation of these terms.</li>
        </ul>



        <h2>8. Disclaimers</h2>
        <ul>
            <li>8.1. Apex Technologies is provided "as is" without any warranties, express or implied.</li>
            <li>8.2. We do not guarantee the availability, accuracy, or reliability of our services.</li>
        </ul>



        <h2>9. Limitation of Liability</h2>
        <ul>
            <li>9.1. In no event shall Apex Technologies be liable for any indirect, consequential, or incidental damages arising out of or in connection with the use of our services.</li>
        </ul>



        <h2>10. Governing Law</h2>
        <ul>
            <li>10.1. These terms are governed by and construed in accordance with the laws of Federal Republic of Nigeria.</li>
            <li>10.2. Any disputes relating to these terms and conditions will be subject to the exclusive jurisdiction of the courts of Federal Republic of Nigeria.</li>
        </ul>



        <h2>11. Intellectual Property</h2>
        <ul>
            <li>11.1. All content on the Website, including but not limited to text, graphics, logos, images, and software, is the property of Apex Technologies and is protected by intellectual property laws.</li>
            <li>11.2. You may not reproduce, distribute, display, or create derivative works of any content on the Website without the express written consent of Apex Technologies.</li>
        </ul>
 


        <h2>12. User Conduct</h2>
        <ul>
            <li>12.1. You agree not to use the Website for any unlawful purpose or in any way that could violate these Terms and Conditions.</li>
            <li>12.2. You will not engage in any conduct that restricts or inhibits any other user from using or enjoying the Website.</li>
        </ul>


        <h2>13. Changes to Terms</h2>
        <ul>
            <li>13.1. We reserve the right to update or modify these terms at any time without prior notice. Your continued use of our services after any changes constitutes acceptance of the revised terms.</li>
        </ul>
  


        <p class="mt-8 font-normal">If you have any questions about these Terms and Conditions, please contact us at 
            <a href="mailto:operations@tech-zone.com">operations@tech-zone.com</a>.
            These Terms and Conditions are effective as of 01/03/2024.
            By using Apex Technologies, you acknowledge that you have read, understood, and agree to these terms and conditions.
        </p>


    <!-- <footer> -->
        <p class="text-sm mt-8">
            <p class="p-0 m-0">Apex Technologies LLC</p>
            <a href="mailto:operations@tech-zone.com">operations@tech-zone.com</a><br/>
            Effective Date: 01/03/2024
        </p>
        
    <!-- </footer> -->
    </div>
</template>

<script>
    export default {
        name: "TermsAndConditionText"
    }
</script>

<style scoped>
    h3{
        @apply font-bold text-lg mt-3 text-blue-500
    }

    h2{
        @apply font-bold text-xl mt-3 text-blue-700
    }

    p{
        @apply font-medium
    }

    a{
        @apply text-orange-400
    }
</style>