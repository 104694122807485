<template>
    <!-- <div> -->
   <!-- MINI FOOTER STARTS HERE -->
   <!-- <div class=" mt-6 p-3 w-full bg-white dark:bg-[#CAD1D8] text-black justify-center items-center text-sm flex flex-row gap-5"> -->
   <div class=" mt-3 p-3 w-full bg-white justify-center items-center text-sm flex flex-row gap-5">
            
            <div class="flex-row gap-3 hidden md:flex text-center justify-center items-center">
                <span>About Us</span>
                <span>Privacy Policy</span>
                <span>Cookie Policy</span>
                <span>Billing/Payment T&Cs</span>
                <span>Use of software T&Cs</span>
                <span>FAQs</span>
            </div>
            <p>&copy;2024 ApexTeks all rights reserved.</p>
    </div>
    
    <!-- MINI FOOTER ENDS HERE -->
    <!-- </div> -->
</template>

<script>
    export default {
        name: "MiniFooter",
    }
</script>

<style scoped>

</style>